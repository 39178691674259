<template>
  <v-container style="max-width: 800px; position: relative;">
      <ViewReport />
      <Footer/>
  </v-container>
</template>

<script>
import ViewReport from '../components/ViewReport.vue'
import Footer from '../components/Footer.vue'

export default {
    components: {
        ViewReport,
        Footer
    },
    layout: 'report',
    mounted: function() {
      if(window.location.host == "vihjaa.fi") {
        window.location.replace("https://vihjaa.fi");
      }
    }
}
</script>

